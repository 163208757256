<template>
	<div class="section">
		<div class="container">
			<div class="section__head">
				<div class="title title--section"><span>Select games for boosting</span></div>
				<div class="section__options">
					<div class="section__option">
						<button class="btn btn--sm" @click="onChangePosition">Position</button>
					</div>
				</div>
			</div>

			<div class="board">
				<ul class="board__list" v-if="hasLoadGames">
					<li class="board__item" v-for="(game, i) in games" :key="game.id">
						<div class="board__card" :style="{'background-image': `url(${game.picture}`}"
								:class="{'not-active': !+game.is_active}">
							<router-link :to="`/games/${game.id}`" class="board__logo">
								<img :src="game.icon" alt="">
							</router-link>

							<div class="board__title">{{game.title}}</div>

							<div class="board__options">
								<router-link :to="`/games/${game.id}`" class="board__option">
									<v-icon name="eye"></v-icon>
								</router-link>
								<div class="board__option" @click="onSave(game)">
									<v-icon name="edit"></v-icon>
								</div>
								<div class="board__option" @click="onDelete(game.id)">
									<v-icon name="trash-alt"></v-icon>
								</div>
							</div>
						</div>
					</li>

					<li class="board__item">
						<div class="board__card board__card--plus">
							<div class="board__plus" @click="onSave(null)">
								<v-icon name="plus"></v-icon>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import GameSave from './GameSave';
	import GamePosition from './GamePosition';

	export default {
		name: 'game-index',
		components: {
		},
		data(){
			return{
				hasLoadGames: false,
				hasInitCommonData: false
			}
		},

		computed:{
			...mapState({
				games: state => state.games.games,
			})
		},

		created(){
			this.getGames();
		},

		methods: {
			...mapActions({
				GET_GAMES: 'games/GET_GAMES',
				DELETE_GAME: 'games/DELETE_GAME'
			}),

			onSave(game){
				this.$modal.show(GameSave, {
					game: game,
					lang: this.selectedLang
				}, $MODAL_OPTIONS.big);
			},

			onDelete(id){
				if (confirm('Do you want delete this game?')){
					this.DELETE_GAME(id).then(() => {
						this.getGames();
					})
				}
			},

			getGames(){
				this.GET_GAMES().then(() => {
					this.hasLoadGames = true;
				})
			},

			onChangePosition(){
				this.$modal.show(GamePosition,{
					games: this.games
				}, $MODAL_OPTIONS.default);
			}
		}
	};
</script>

<style scoped lang=scss>
	.board {
		&__list {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
		}
		&__item {
			width: 20%;
			padding: 0 15px;
			margin-bottom: 30px;
			transition: all 0.3s;

		}
		&__card {
			background: #fff;
			border-radius: 5px;
			transition: all 0.3s;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
			position: relative;
			height: 150px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			padding: 10px;
			&:before{
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				border-radius: 5px;
				background: rgba(0,0,0,0.6);
				transition: all 0.3s;
			}
			&.not-active{
				filter: grayscale(100%);
				opacity: 0.8;
			}
			&:hover {
				box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.5);
				&:before{
					background: rgba(0,0,0,0.7);
				}
				.board__options{
					opacity: 1;
				}
			}
			&--plus {
				background: rgba(255, 255, 255, 0.3);
				cursor: pointer;
				box-shadow: none;
				padding: 0;
				height: 150px;
				&:before{
					display: none;
				}
				&:hover {
					background: #fff;
					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
					svg {
						width: 70px;
						height: 70px;
					}
				}
			}
		}
		&__title{
			color: #fff;
			font-size: 12px;
			position: relative;
			font-weight: bold;
		}
		&__plus{
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			svg{
				width: 60px;
				height: 60px;
				fill: rgba(0,0,0,0.2);
			}
		}
		&__logo{
			position: relative;
			max-width: 100px;
			height: 65px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.3s;
			&:hover{
				opacity: 0.8;
			}
			img{
				max-height: 100%;
			}
		}

		&__options{
			width: 100%;
			display: flex;
			justify-content: center;
			transition: all 0.3s;
			position: absolute;
			bottom: 5px;
			left: 0;
			opacity: 0.3;
		}
		&__option{
			padding: 5px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
			width: 40px;
			height: 40px;
			margin: 0 5px;
			/*background: rgba(255,255,255,0.3);*/
			line-height: 0;
			transition: all 0.3s;
			svg{
				fill: #fff;
			}
			&:hover{
				background: rgba(255,255,255,0.8);
				svg{
					fill: $c_main;
				}
			}
		}
	}
</style>

<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="gamepad"></v-icon>
						</div>
						<div class="form__title">{{hasEdit ? 'Edit' : 'Create'}} game for boosting</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--7">
							<div class="form__item">
								<form-input v-model="formData.title"
										label="title"
										rules="required"
										@enter="onSave"
										name="title"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="formData.key"
										label="Key"
										rules="required"
										@enter="onSave"
										name="key"></form-input>
							</div>

							<div class="grid">
								<div class="grid__coll grid__coll--4">
									<div class="form__item">
										<form-checkbox v-model="formData.is_active"
												label="Is active"
												name="is_active"></form-checkbox>
									</div>
								</div>
								<div class="grid__coll grid__coll--4">
									<div class="form__item">
										<form-checkbox v-model="formData.is_hot"
												label="Is hot"
												name="is_hot"></form-checkbox>
									</div>
								</div>
								<div class="grid__coll grid__coll--4">
									<div class="form__item">
										<form-checkbox v-model="formData.is_new"
												label="Is new"
												name="is_new"></form-checkbox>
									</div>
								</div>
							</div>


							<div class="lang">
								<div class="lang__tabs">
									<div class="lang__tab" v-for="lang in languages"
											:class="{'has-active': lang.code === selectedLang}"
											@click="selectedLang = lang.code">{{lang.code}}</div>
								</div>
								<div class="lang__cnt">
									<div class="lang__form" v-for="lang in languages" v-if="lang.code === selectedLang">
										<div class="form__item">
											<form-input v-model="formData.seo_title[lang.code]"
													name="seo_title"
													@enter="onSave"
													label="Seo title"></form-input>
										</div>

										<div class="form__item">
											<form-textarea v-model="formData.seo_description[lang.code]"
													name="seo_description"
													@enter="onSave"
													label="Seo description"></form-textarea>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="grid__coll grid__coll--5">
							<div class="form__item">
								<form-img v-model="formData.icon"
										class="has-md"
										label="Add logo"
										name="icon"></form-img>
							</div>

							<div class="form__item">
								<form-img v-model="formData.picture"
										class="has-md"
										label="Add ground"
										name="picture"></form-img>
							</div>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': hasLoading}"
								@click="onSave"><span>{{hasEdit ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import FormTextEdit from '@form/FormTextEdit';
	import FormTextarea from '@form/FormTextarea';
	import FormInput from '@form/FormInput';
	import FormImg from '@form/FormImg';
	import FormCheckbox from '@form/FormCheckbox';

	export default {
		name: 'game-save',
		components: {
			FormInput,
			FormImg,
			FormCheckbox,
			FormTextEdit,
			FormTextarea
		},
		props: ['game', 'lang'],
		data(){
			return {
				selectedLang : this.$store.state.selectedLang,

				formData:{
					title: null,
					key: null,
					icon: '',
					picture: '',
					is_active: false,
					is_hot: false,
					is_new: false,
					seo_title: {},
					seo_description: {},
				},


				hasLoading: false
			}
		},

		computed:{
			...mapState({
				DEFAULT_LANG: state => state.DEFAULT_LANG,
				languages: state => state.languages.list,
			}),

			hasEdit(){
				return !!this.game;
			},

			hasTranslate(){
				return 	this.lang.code !== this.DEFAULT_LANG.code
			},
		},

		created(){
			if(this.hasEdit) this.buildData();
		},

		methods: {
			...mapActions({
				SAVE_GAME: 'games/SAVE_GAME',
				GET_GAMES: 'games/GET_GAMES'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						this.hasLoading = true;
						let formData = $fn.cloneDeep(this.formData);

						this.SAVE_GAME($fn.assign(formData,{
							id: this.hasEdit ? this.game.id : null,

						})).then(() => {
							this.hasLoading = false;

							this.GET_GAMES().then(() => {
								this.$emit('close');
							});

						}).catch(() => {
							this.hasLoading = false;
						});
					}
				});
			},

			buildData(){
				$fn.assign(this.formData, this.game);

				// let game = this.game;
				//
				// this.title = game.title;
				// this.key = game.key;
				// this.icon = game.icon;
				// this.picture = game.picture;
				// this.is_active = +game.is_active;
				//
				// this.seo_title = game.seo_title[this.lang.code];
				// this.seo_description = game.seo_description[this.lang.code];
			}
		}
	};
</script>

<style scoped lang=scss>

</style>

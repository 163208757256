<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="gamepad"></v-icon>
						</div>
						<div class="form__title">Change game position</div>
					</div>

					<div class="grid form__wrap">
						<div class="grid__coll grid__coll--2">
							<ul class="list">
								<li class="list__item" v-for="index in list.length">
									<div class="list__card">{{index}}</div>
								</li>
							</ul>
						</div>

						<div class="grid__coll grid__coll--10">
							<draggable
									class="list-group"
									tag="ul"
									v-model="list"
									v-bind="dragOptions"
									@start="drag = true"
									@end="drag = false">
								<transition-group type="transition" :name="!drag ? 'flip-list' : null">
									<li class="item"
											v-for="element in list"
											:key="element.order">
										<div class="item__card" :class="{'not-active': !+element.is_active}">
											<div class="item__icon" :style="{'background-image': `url(${element.icon}`}"></div>
											<div class="item__title">{{ element.title }}</div>
											<div class="item__drop">
												<v-icon name="bars"></v-icon>
											</div>
										</div>
									</li>
								</transition-group>
							</draggable>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn" :class="{'has-loading': hasLoading}" @click="onSave"><span>Save</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {assign, map} from 'lodash';
	import draggable from 'vuedraggable'

	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/gamepad';
	import 'vue-awesome/icons/bars';
	import VIcon from 'vue-awesome/components/Icon';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('games');

	export default {
		name: 'game-position',
		components: {
			VIcon,
			draggable
		},
		props: ['games'],
		data(){
			return {
				list: map(this.games, (game, index) => {
					return assign(game, {order: index + 1})
				}),

				drag: false,
				hasLoading: false,

				dragOptions:{
					animation: 200,
					group: "description",
					disabled: false,
					ghostClass: "ghost"
				}
			}
		},

		methods: {
			...mapActions([
				'CHANGE_POSITION',
				'GET_GAMES'
			]),

			onSave(){
				this.hasLoading = true;

				this.CHANGE_POSITION(map(this.list, item => {
					return item.id

				})).then(() => {
					this.GET_GAMES().then(() => {
						this.hasLoading = false;
						this.$emit('close');
					});

				}).catch(() => {
					this.hasLoading = false;
				})
			}
		}
	};
</script>

<style scoped lang=scss>
	.item{
		padding: 5px 0 ;
		&__card{
			padding: 10px 10px;
			box-shadow: 0 1px 8px rgba(0,0,0,0.2);
			display: flex;
			align-items: center;
			border-radius: 5px;
			position: relative;
			cursor: move;
			&.not-active{
				filter: grayscale(100%);
				opacity: 0.8;
				background: rgba(0,0,0,0.08);
			}
		}
		&__icon{
			width: 80px;
			height: 40px;
			margin-right: 20px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}
		&__title{
			font-family: $f_alt;
		}
		&__drop{
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			line-height: 0;
		}
	}

	.form{
		&__wrap{
			margin-bottom: 20px;
		}
	}

	.list{
		&__item{
			padding: 5px 0;
		}
		&__card{
			padding: 10px 10px;
			box-shadow: 0 1px 8px rgba(0,0,0,0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			height: 60px;
			font-weight: bold;
			font-size: 18px;
		}
	}
</style>
